import React from 'react'
import classNames from 'classnames'

import { requestForAutoOpenBarFaster } from '../../apiRequests/callToAction'
import DefaultLayout from '../../layout/default'
import CtaForm from '../../pagesSections/index/CtaForm'
import SimpleFeatures from '../../components/_V2/SimpleFeatures'
import BarCtaForm from '../../pagesSections/avtomatizaciya-bara/BarCtaForm'
import ProductPresentation from '../../pagesSections/avtomatizaciya-bara/ProductPresentation'
import Presentation
  from '../../pagesSections/avtomatizaciya-bara/ProductPresentation/assets/bar-presentation.png'
import AutomationMenu from '../../components/_V2/AutomationMenu'

import featuresData from '../../pages-data/_V2/automatization/bar/features'
import pageStyles from '../../styles/_V2/pagesStyles/styles.module.scss'
import BarBenefits from '../../pagesSections/avtomatizaciya-bara/BarBenefits'
import styles from '../styles/_V2/automation.module.scss'
import AccountingFeatures from '../../pagesSections/intergations/AccountingFeatures'
import ProductsFeatures from '../../pagesSections/intergations/ProductsFeatures'
import AdditionalServices from '../../pagesSections/index/AdditionalServices'

export default function PageCoffeehouse() {
  const title = 'Автоматизация бара — CRM система с программой учета и автоматизацией бара | Quick Resto'
  const description = 'Комплексная система автоматизации бара ✔ Онлайн-касса ✔ Аналитика продаж и складской учет в облаке ✔ Единая система обслуживания → Бесплатный пробный период 14 дней'
  const metaTags = [
    // {
    //   name: 'keywords',
    //   content: 'quick resto, автоматизация кофейни, ресторанный бизнес, программа учёта, склад, общепит',
    // },
    {
      name: 'description',
      content: description,
    },
    {
      property: 'og:url',
      content: 'https://quickresto.ru/automation/avtomatizaciya-bara/',
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: `https://quickresto.ru${Presentation}`,
    },
  ]

  return (
    <DefaultLayout
      title={title}
      metaTags={metaTags}
      mainWrapperClassName={classNames(
			  pageStyles.pageWrapper,
			  pageStyles.pageGray,
			  pageStyles.automationPage,
      )}
      linkCanonical="https://quickresto.ru/automation/avtomatizaciya-bara/"
      footerClassName={styles.automation__footer}
      schemaType="Product"
      schemaName={title}
      schemaDescription={description}
      schemaImage={metaTags.find((tag) => tag.property === 'og:image').content}
      schemaUrl={metaTags.find((tag) => tag.property === 'og:url').content}
    >
      <div
        className={classNames(
					  pageStyles.pageSection,
					  styles.automation__headline,
        )}
      >
        <ProductPresentation
          leftColumn={styles.automation__presentationLeftColumn}
          title={styles.automation__presentationTitle}
        />

        <AutomationMenu className={styles.automation__menu} />
      </div>

      <SimpleFeatures
        data={featuresData.filter((_, index) => index < 2)}
        className={pageStyles.pageSection}
      />

      <BarCtaForm
        className={pageStyles.pageSection}
        request={requestForAutoOpenBarFaster}
      />

      <SimpleFeatures
        data={featuresData.filter((_, index) => index === 2)}
        className={pageStyles.pageSection}
      />

      <BarBenefits className={pageStyles.pageSection} />

      <SimpleFeatures
        data={featuresData.filter((_, index) => index > 2 && index < 5)}
        className={pageStyles.pageSection}
      />

      <AccountingFeatures
        className={pageStyles.pageSection}
        theme="white"
      />

      <ProductsFeatures
        className={pageStyles.pageSection}
        theme="white"
      />

      <AdditionalServices
        className={styles.additionalServices}
        theme="white"
      />

      <SimpleFeatures
        data={featuresData.filter((_, index) => index >= 5)}
        className={pageStyles.pageSection}
      />

      <CtaForm
        isFry={false}
        isWhiteBackground
        className={pageStyles.pageSection}
      />
    </DefaultLayout>
  )
}
