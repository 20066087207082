import React, {useEffect} from "react"
import classNames from "classnames"

import {HEADER_THEMES} from "../components/_V2/Header"

import {JsonLd} from "react-schemaorg";
import {ContactPoint, Organization} from "schema-dts";
import {Product} from "schema-dts";

import { insertB24AnalyticScript, insertMangoCallback, insertGtmScript } from "../utils"

import AllSizedHelmet from "../components/AllSizedHelmet"
import HeaderWrapper from "../components/_V2/Header/HeaderWrapper"
import Footer, {FOOTER_THEMES} from "../components/_V2/Footer"

import styles from "../styles/_V2/pagesStyles/styles.module.scss"
import { useLocalizationContext } from "../localization/useLocalizationContext";

interface Props {
    children: React.ReactNode
    title: string
    metaTags: Record<string, string>[]
    linkCanonical: string
    mainWrapperClassName: string
    headerTheme: HEADER_THEMES
    footerTheme: FOOTER_THEMES
    footerClassName: string
    isFooterEnable: boolean
    isHeaderFixed: boolean
    schemaType?: string
    schemaName?: string
    schemaDescription?: string
    schemaUrl?: string
    schemaImage?: string
}

export default function DefaultLayout({
                                          children,
                                          title,
                                          metaTags,
                                          linkCanonical,
                                          mainWrapperClassName,
                                          headerTheme = HEADER_THEMES.DARK,
                                          footerTheme = FOOTER_THEMES.WHITE,
                                          footerClassName,
                                          isFooterEnable = true,
                                          isHeaderFixed = false,
                                          schemaType,
                                          schemaName,
                                          schemaDescription,
                                          schemaUrl,
                                          schemaImage
                                      }: Props) {
    const localizationContext = useLocalizationContext()
    //Скрипт аналитики битрикс24
    useEffect(() => {
        insertB24AnalyticScript()
        localizationContext.locale === 'ru-BY' && insertGtmScript()
    })
    // Вставить скрипт через react-helmet не получается из-за
    // https://github.com/nfl/react-helmet/issues/419.
    // Добавить скрипт в скрипт по умолчанию в react-helmet'е (там, где Chatra) не
    // получается: возникают неопределённые переменные в скрипте от Mango.
    // Поэтому после монтирования странички создаём новый тег script
    // в body с содержимым виджета Mango:
    useEffect(() => {
        setTimeout(insertMangoCallback, 6500)
    })

    return (
        <>
            {schemaType === 'Product' ?
                (
                    <JsonLd<Product> item={{
                        "@context": "https://schema.org",
                        "@type": "Product",
                        name: schemaName,
                        image: schemaImage,
                        description: schemaDescription,
                        brand: "Quickresto",
                        offers: {
                            "@type": "Offer",
                            "priceCurrency": "RUB",
                            "price": "1990",
                            "availability": ["https://schema.org/InStock"],
                            "url": schemaUrl ?? "https://quickresto.ru/price/"
                        }

                    }}
                    />) : (<JsonLd<Organization>
                        item={{
                            "@context": "https://schema.org",
                            "@type": "Organization",
                            name: "Quickresto",
                            url: "https://quickresto.ru",
                            image: "https://quickresto.ru/assets/img/logo_mail.png",
                            contactPoint: [{
                                "@type": "ContactPoint",
                                "telephone": "8-800-500-2138",
                                "contactType": "Customer service",
                                "areaServed": "RU",
                                "availableLanguage": "Russian"
                            }],
                            sameAs: ["https://www.youtube.com/channel/UCWvHmsk_UwaKepM6oHoJJgw"]
                        }}
                    />
                )}

            <main className={classNames(styles.pageWrapper, mainWrapperClassName)}>
                <AllSizedHelmet>
                    {/* <meta name="robots" content="noindex, nofollow" />*/}
                    <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"/>
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1, maximum-scale=5"
                    />
                    <meta httpEquiv="ScreenOrientation" content="autoRotate:disabled"/>
                    <meta name="robots" content="index, follow"/>
                    {typeof document !== "undefined" && (
                        <meta
                            http-equiv="Last-Modified"
                            content={new Date(document.lastModified).toUTCString()}
                        />
                    )}
                    {Array.isArray(metaTags) &&
                        metaTags.map((attrs: Record<string, string>, idx) => (
                            <meta key={idx} {...attrs} />
                        ))}
                    <title>{title}</title>
                    {linkCanonical && <link rel="canonical" href={linkCanonical}/>}

                </AllSizedHelmet>

                <HeaderWrapper
                    headerProps={{
                        theme: headerTheme,
                        alwaysOnWhiteBackground: isHeaderFixed,
                    }}
                />

                {children}

                {isFooterEnable && (
                    <Footer theme={footerTheme} className={footerClassName}/>
                )}
                {/* Обратный звонок сервиса "Mango" */}
                <div
                    className="mango-callback"
                    data-settings='{"type":"", "id": "MTAwMDI1NjY=","autoDial" : "0", "lang" : "ru-ru", "host":"lk.mango-office.ru/", "errorMessage": "В данный момент наблюдаются технические проблемы и совершение звонка невозможно"}'
                >
                    <button
                        className="button-widget-open"
                        id="mango-widget-btn"
                        style={{display: "none"}}
                    >
                        ☎
                    </button>
                </div>
            </main>
        </>
    )
}
