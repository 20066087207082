import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery"
import mediaContent from "./mediaContent.module.scss"
import Backdrop from "./assets/Backdrop"
import {Locales} from "../../../localization/types";
import classNames from "classnames";

const wlsiteLink = 'https://demopir2024.menuquickresto.ru/';

export const getData = (locale: Locales): {
	features: Array<FeatureGalleryItem>
	mediaContent: JSX.Element
} => {
	if (locale === 'ru-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Доставка",
					desktopTitle: "Предложи доставку или самовывоз",
					mobileTitle: "Предложи доставку или самовывоз",
					desktopContent: "Приложение и сайт поддерживают оба типа заказов — на доставку и самовывоз. Автоматический расчёт стоимости доставки по зонам города поможет клиенту решить, как ему удобнее получить заказ.",
					tabletContent: "Приложение и сайт поддерживают два типа заказов — на доставку и самовывоз. Автоматический расчёт стоимости доставки по зонам города поможет гостю решить, как ему удобней забрать заказ в этот раз.",
					mobileContent: "Приложение и сайт поддерживают два типа заказов — на доставку и самовывоз. Автоматический расчёт стоимости доставки по зонам города поможет гостю решить, как ему удобней забрать заказ \nв этот раз.",
					backdropContent: (
						<>
							<svg className={mediaContent.backdrop__desktop} width="306" height="306" viewBox="0 0 306 306" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
								<circle cx="153" cy="153" r="153" fill="url(#pattern0)" fillOpacity="0.2"/>
								<defs>
									<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="0.0653595" height="0.0669935">
										<use xlinkHref="#image0_1992_22780" transform="scale(0.00163399)"/>
									</pattern>
									<image id="image0_1992_22780" width="40" height="41" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAApCAYAAABHomvIAAAAgklEQVRYCe3T4QmAIBCG4QbQWzk30LMJ6tqvEQqpwP75S4Re4UBE8fNRp4mGAAIIIIAAAggggAACgwjIYrNXO0pJ3uMgse4Ykrfo1c66RNcwTMhH7hOwjBGwVUDUQn29pe+ipdb1XeY5tfR+EhdtnPfX5fRsggACCCCAAAIIIPBLgQuMFkPGdY5+vgAAAABJRU5ErkJggg=="/>
								</defs>
							</svg>

							<Backdrop className={classNames(mediaContent.backdrop, mediaContent.backdrop__other)} />
						</>
					),
					detailText: 'Посмотреть пример сайта',
					detailLink: wlsiteLink
				},
				{
					desktopContentWidth: 490,
					tabName: "Самовывоз",
					desktopTitle: <>Вручи заказ <br/>прямо в руки</>,
					tabletTitle: <>Вручи заказ прямо в руки</>,
					mobileTitle: <>Вручи заказ <br/>прямо в руки</>,
					desktopContent:
						"Гость любит заведение, но в этот раз не может забежать на подольше? Не проблема: доступен выбор блюд с модификаторами, оплата картами и бонусами. \n" +
						"Мы отправим пуш, когда заказ можно будет забирать.",
					mobileContent:
						"Гость любит заведение, но в этот раз не может забежать на подольше? Не проблема! Выбор блюд с моди-фикаторами, оплата картами и бонусами. Мы отправим пуш, когда заказ будет готов. Можно забирать!",
					backdropContent: (
						<>
							<svg className={mediaContent.backdrop__desktop} width="306" height="306" viewBox="0 0 306 306" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
								<circle cx="153" cy="153" r="153" fill="url(#pattern0)" fillOpacity="0.2"/>
								<defs>
									<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="0.0653595" height="0.0669935">
										<use xlinkHref="#image0_1992_22780" transform="scale(0.00163399)"/>
									</pattern>
									<image id="image0_1992_22780" width="40" height="41" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAApCAYAAABHomvIAAAAgklEQVRYCe3T4QmAIBCG4QbQWzk30LMJ6tqvEQqpwP75S4Re4UBE8fNRp4mGAAIIIIAAAggggAACgwjIYrNXO0pJ3uMgse4Ykrfo1c66RNcwTMhH7hOwjBGwVUDUQn29pe+ipdb1XeY5tfR+EhdtnPfX5fRsggACCCCAAAIIIPBLgQuMFkPGdY5+vgAAAABJRU5ErkJggg=="/>
								</defs>
							</svg>

							<Backdrop className={classNames(mediaContent.backdrop, mediaContent.backdrop__other)} />
						</>
					),
					detailText: 'Посмотреть пример сайта',
					detailLink: wlsiteLink
				},
			] as Array<FeatureGalleryItem>,
			mediaContent: (
				<div className={mediaContent.images}>
					<StaticImage
						className={mediaContent.image}
						src="./assets/wl-phone-delivery-KZ.png"
						alt={"мобильное приложение для доставки"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>

					<StaticImage
						className={mediaContent.image}
						src="./assets/wl-phone-pickup-KZ.png"
						alt={"предзаказ еды в приложении для кафе"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				</div>
			) as JSX.Element,
		}
	}

	if (locale === 'kz-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Жеткізу",
					desktopTitle: "Жеткізуді немесе өзімен әкетуді ұсыныңыз",
					desktopContent: "Қосымша мен сайт тапсырыстардың екі түрін қолдайды - жеткізуге және өзімен әкетуге. Қаланың аймақтары бойынша жеткізу құнын автоматты түрде есептеу қонаққа осы жолы тапсырысты қалай ыңғайлы әкету болатынын шешуіне көмектеседі.",
					backdropContent: (
						<>
							<svg className={mediaContent.backdrop__desktop} width="306" height="306" viewBox="0 0 306 306" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
								<circle cx="153" cy="153" r="153" fill="url(#pattern0)" fillOpacity="0.2"/>
								<defs>
									<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="0.0653595" height="0.0669935">
										<use xlinkHref="#image0_1992_22780" transform="scale(0.00163399)"/>
									</pattern>
									<image id="image0_1992_22780" width="40" height="41" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAApCAYAAABHomvIAAAAgklEQVRYCe3T4QmAIBCG4QbQWzk30LMJ6tqvEQqpwP75S4Re4UBE8fNRp4mGAAIIIIAAAggggAACgwjIYrNXO0pJ3uMgse4Ykrfo1c66RNcwTMhH7hOwjBGwVUDUQn29pe+ipdb1XeY5tfR+EhdtnPfX5fRsggACCCCAAAIIIPBLgQuMFkPGdY5+vgAAAABJRU5ErkJggg=="/>
								</defs>
							</svg>

							<Backdrop className={classNames(mediaContent.backdrop, mediaContent.backdrop__other)} />
						</>
					),
					detailText: 'Сайттың мысалын қараңыз',
					detailLink: wlsiteLink
				},
				{
					desktopContentWidth: 490,
					tabName: "Өзімен әкету",
					desktopTitle: <>Тапсырысты тікелей<br />қолына тапсырыңыз</>,
					tabletTitle: <>Тапсырысты тікелей қолына тапсырыңыз</>,
					mobileTitle: <>Тапсырысты тікелей<br />қолына тапсырыңыз</>,
					desktopContent:
						"Қонаққа мекеме ұнайды, бірақ бұл жолы ұзақ уақытқа кіре алмай ма? Еш қиындығы жоқ: модификаторлармен тағамдарды таңдау, карталармен және бонустармен төлеу қолжетімді. Тапсырысты әкету мүмкін болатын кезде біз пуш жібереміз.",
					backdropContent: (
						<>
							<svg className={mediaContent.backdrop__desktop} width="306" height="306" viewBox="0 0 306 306" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
								<circle cx="153" cy="153" r="153" fill="url(#pattern0)" fillOpacity="0.2"/>
								<defs>
									<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="0.0653595" height="0.0669935">
										<use xlinkHref="#image0_1992_22780" transform="scale(0.00163399)"/>
									</pattern>
									<image id="image0_1992_22780" width="40" height="41" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAApCAYAAABHomvIAAAAgklEQVRYCe3T4QmAIBCG4QbQWzk30LMJ6tqvEQqpwP75S4Re4UBE8fNRp4mGAAIIIIAAAggggAACgwjIYrNXO0pJ3uMgse4Ykrfo1c66RNcwTMhH7hOwjBGwVUDUQn29pe+ipdb1XeY5tfR+EhdtnPfX5fRsggACCCCAAAIIIPBLgQuMFkPGdY5+vgAAAABJRU5ErkJggg=="/>
								</defs>
							</svg>

							<Backdrop className={classNames(mediaContent.backdrop, mediaContent.backdrop__other)} />
						</>
					),
					detailText: 'Сайттың мысалын қараңыз',
					detailLink: wlsiteLink
				},
			] as Array<FeatureGalleryItem>,
			mediaContent: (
				<div className={mediaContent.images}>
					<StaticImage
						className={mediaContent.image}
						src="./assets/wl-phone-delivery-KZ.png"
						alt={"мобильное приложение для доставки"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>

					<StaticImage
						className={mediaContent.image}
						src="./assets/wl-phone-pickup-KZ.png"
						alt={"предзаказ еды в приложении для кафе"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				</div>
			) as JSX.Element,
		}
	}

	if (locale === 'ru-BY') {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Доставка",
					desktopTitle: "Предложи доставку или самовывоз",
					mobileTitle: "Предложи доставку или самовывоз",
					desktopContent:
						"Приложение и сайт поддерживают два типа заказов — на доставку и самовывоз. Автоматический расчёт стоимости доставки по зонам города поможет гостю решить, как ему удобней забрать заказ в этот раз.",
					backdropContent: (
						<>
							<svg className={mediaContent.backdrop__desktop} width="306" height="306" viewBox="0 0 306 306" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
								<circle cx="153" cy="153" r="153" fill="url(#pattern0)" fillOpacity="0.2"/>
								<defs>
									<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="0.0653595" height="0.0669935">
										<use xlinkHref="#image0_1992_22780" transform="scale(0.00163399)"/>
									</pattern>
									<image id="image0_1992_22780" width="40" height="41" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAApCAYAAABHomvIAAAAgklEQVRYCe3T4QmAIBCG4QbQWzk30LMJ6tqvEQqpwP75S4Re4UBE8fNRp4mGAAIIIIAAAggggAACgwjIYrNXO0pJ3uMgse4Ykrfo1c66RNcwTMhH7hOwjBGwVUDUQn29pe+ipdb1XeY5tfR+EhdtnPfX5fRsggACCCCAAAIIIPBLgQuMFkPGdY5+vgAAAABJRU5ErkJggg=="/>
								</defs>
							</svg>

							<Backdrop className={classNames(mediaContent.backdrop, mediaContent.backdrop__other)} />
						</>
					),
					detailText: 'Посмотреть пример сайта',
					detailLink: wlsiteLink
				},
				{
					desktopContentWidth: 490,
					tabName: "Самовывоз",
					desktopTitle: "Прямо в руки",
					mobileTitle: "Прямо в руки",
					desktopContent:
						"Гость любит заведение, но в этот раз не может забежать на подольше? Не проблема! Выбор блюд с модификаторами, оплата картами и бонусами. Мы отправим пуш, когда заказ будет готов. Можно забирать!",
					backdropContent: (
						<>
							<svg className={mediaContent.backdrop__desktop} width="306" height="306" viewBox="0 0 306 306" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
								<circle cx="153" cy="153" r="153" fill="url(#pattern0)" fillOpacity="0.2"/>
								<defs>
									<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="0.0653595" height="0.0669935">
										<use xlinkHref="#image0_1992_22780" transform="scale(0.00163399)"/>
									</pattern>
									<image id="image0_1992_22780" width="40" height="41" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAApCAYAAABHomvIAAAAgklEQVRYCe3T4QmAIBCG4QbQWzk30LMJ6tqvEQqpwP75S4Re4UBE8fNRp4mGAAIIIIAAAggggAACgwjIYrNXO0pJ3uMgse4Ykrfo1c66RNcwTMhH7hOwjBGwVUDUQn29pe+ipdb1XeY5tfR+EhdtnPfX5fRsggACCCCAAAIIIPBLgQuMFkPGdY5+vgAAAABJRU5ErkJggg=="/>
								</defs>
							</svg>

							<Backdrop className={classNames(mediaContent.backdrop, mediaContent.backdrop__other)} />
						</>
					),
					detailText: 'Посмотреть пример сайта',
					detailLink: wlsiteLink
				},
			] as Array<FeatureGalleryItem>,
			mediaContent: (
				<div className={mediaContent.images}>
					<StaticImage
						className={mediaContent.image}
						src="./assets/wl-phone-delivery-BY.png"
						alt={"мобильное приложение для доставки"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>

					<StaticImage
						className={mediaContent.image}
						src="./assets/wl-phone-pickup-BY.png"
						alt={"предзаказ еды в приложении для кафе"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				</div>
			) as JSX.Element,
		}
	}

	return {
		features: [
			{
				desktopContentWidth: 490,
				tabName: "Доставка",
				desktopTitle: "У вас доставка или самовывоз?",
				mobileTitle: "У вас доставка или самовывоз?",
				desktopContent:
					"Сайт и мобильное приложение для ресторана поддерживают два типа заказов — на доставку и самовывоз. Автоматический расчёт стоимости доставки по зонам города поможет гостю решить, как ему удобней забрать заказ в этот раз.",
				backdropContent: (
					<>
						<svg className={mediaContent.backdrop__desktop} width="306" height="306" viewBox="0 0 306 306" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
							<circle cx="153" cy="153" r="153" fill="url(#pattern0)" fillOpacity="0.2"/>
							<defs>
								<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="0.0653595" height="0.0669935">
									<use xlinkHref="#image0_1992_22780" transform="scale(0.00163399)"/>
								</pattern>
								<image id="image0_1992_22780" width="40" height="41" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAApCAYAAABHomvIAAAAgklEQVRYCe3T4QmAIBCG4QbQWzk30LMJ6tqvEQqpwP75S4Re4UBE8fNRp4mGAAIIIIAAAggggAACgwjIYrNXO0pJ3uMgse4Ykrfo1c66RNcwTMhH7hOwjBGwVUDUQn29pe+ipdb1XeY5tfR+EhdtnPfX5fRsggACCCCAAAIIIPBLgQuMFkPGdY5+vgAAAABJRU5ErkJggg=="/>
							</defs>
						</svg>

						<Backdrop className={classNames(mediaContent.backdrop, mediaContent.backdrop__other)} />
					</>
				),
				detailText: 'Посмотреть пример сайта',
				detailLink: wlsiteLink
			},
			{
				desktopContentWidth: 490,
				tabName: "Самовывоз",
				desktopTitle: "Отдай заказ \nпрямо в руки",
				mobileTitle: "Отдай заказ \nпрямо в руки",
				desktopContent:
					"Гость любит заведение, но в этот раз не может забежать на подольше? Не проблема! Выбор блюд с модификаторами, оплата картами и бонусами. Мы отправим пуш, когда заказ будет готов. Можно забирать!",
				backdropContent: (
					<>
						<svg className={mediaContent.backdrop__desktop} width="306" height="306" viewBox="0 0 306 306" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
							<circle cx="153" cy="153" r="153" fill="url(#pattern0)" fillOpacity="0.2"/>
							<defs>
								<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="0.0653595" height="0.0669935">
									<use xlinkHref="#image0_1992_22780" transform="scale(0.00163399)"/>
								</pattern>
								<image id="image0_1992_22780" width="40" height="41" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAApCAYAAABHomvIAAAAgklEQVRYCe3T4QmAIBCG4QbQWzk30LMJ6tqvEQqpwP75S4Re4UBE8fNRp4mGAAIIIIAAAggggAACgwjIYrNXO0pJ3uMgse4Ykrfo1c66RNcwTMhH7hOwjBGwVUDUQn29pe+ipdb1XeY5tfR+EhdtnPfX5fRsggACCCCAAAIIIPBLgQuMFkPGdY5+vgAAAABJRU5ErkJggg=="/>
							</defs>
						</svg>

						<Backdrop className={classNames(mediaContent.backdrop, mediaContent.backdrop__other)} />
					</>
				),
				detailText: 'Посмотреть пример сайта',
				detailLink: wlsiteLink
			},
		] as Array<FeatureGalleryItem>,
		mediaContent: (
			<div className={mediaContent.images}>
				<StaticImage
					className={mediaContent.image}
					src="./assets/wl-phone-delivery-BY.png"
					alt={"мобильное приложение для доставки"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>

				<StaticImage
					className={mediaContent.image}
					src="./assets/wl-phone-pickup-BY.png"
					alt={"предзаказ еды в приложении для кафе"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			</div>
		) as JSX.Element,
	}
}
